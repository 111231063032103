import { CompanyTypes } from 'api/types/ReferenceDataTS';
import { Permissions } from 'components/common/User/Permissions';

export interface EventUser {
  user: {
    firstname: string;
    lastname: string;
    email: string;
    country_name: string;
    key: string;
    department?: string;
  };
  company: {
    key: string;
    name: string;
    type: CompanyTypes;
  };
  status?: UserStatus;
  is_email_validated: boolean;
  is_internal_user: boolean;
  created_at: string;
  is_under_review: boolean;
  user_type: EventUserType;
  admin_notes?: string;
  permissions?: PortalPermissions;
}

export enum UserStatus {
  VERIFIED = 'VERIFIED',
  BLOCKED = 'BLOCKED'
}

export enum EventUserType {
  COMPETITOR = 'COMPETITOR',
  DIRECT_CUSTOMER = 'DIRECT_CUSTOMER',
  DISTRIBUTOR = 'DISTRIBUTOR',
  INDIRECT_CUSTOMER = 'INDIRECT_CUSTOMER',
  OTHER = 'OTHER'
}

export enum BrandPortalPermissionLevel {
  MANAGE = 'manage'
  // the permission will be undefined if the user should have no access
}
export enum PortalPermissionLevel {
  MANAGE = 'manage',
  VIEW = 'view'
  // the permission will be undefined if the user should have no access
}

export enum SupplierPortalPermissionKey {
  PRODUCT_MANAGEMENT = 'product.portal.supplier',
  USER_MANAGEMENT = 'user.portal.supplier',
  COMPANY_MANAGEMENT = 'company.portal.supplier'
}

export enum BrandPortalPermissionKey {
  PRODUCT_MANAGEMENT = 'product.portal.brand',
  USER_MANAGEMENT = 'user.portal.brand',
  COMPANY_MANAGEMENT = 'company.portal.brand',
  DOCUMENT_MANAGEMENT = 'document.portal.brand',
  SEARCH = 'search.portal.brand'
}

export type SupplierPortalPermissions = {
  [key in SupplierPortalPermissionKey]?: PortalPermissionLevel;
};

export type BrandPortalPermissions = {
  [key in BrandPortalPermissionKey]?: PortalPermissionLevel;
};

export type PortalPermissions = SupplierPortalPermissions &
  BrandPortalPermissions;

export type UserSettings = {
  event_user?: {
    status: 'VERIFIED';
  };
  permissions?: Permissions;
  notifications?: {
    [x: string]: boolean;
  };
};

export enum PortalTypes {
  BRAND_PORTAL = 'BRAND_PORTAL',
  SUPPLIER_PORTAL = 'SUPPLIER_PORTAL'
}
