import {
  PermissionKey,
  PermissionLevel
} from 'components/common/User/Permissions';
import { useUser } from 'context/User';
import PropTypes from 'prop-types';
import React from 'react';
import { LoggedInUser } from 'types/User';

export const Roles: React.FC<
  React.PropsWithChildren<{
    validRoles: string[];
    excludeRoles?: string[];
  }>
> = ({ validRoles, children, excludeRoles = [] }) => {
  const user = useUser();
  const allowUser = hasAccess(validRoles, excludeRoles, user);
  if (!allowUser) return null;

  return <>{children}</>;
};
Roles.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.element)
  ])
};

export const hasAccess = (
  validRoles: string[] | undefined,
  excludeRoles: string[] | undefined,
  user?: LoggedInUser
) => {
  let allowUser = false;

  if (user) {
    if (isSupport(user)) {
      allowUser = true;
    } else {
      validRoles?.forEach(role => {
        if (user.roles?.includes(role)) {
          allowUser = true;
        }
      });

      if (excludeRoles) {
        excludeRoles.forEach(role => {
          if (user.roles?.includes(role)) {
            allowUser = false;
          }
        });
      }
    }
  }
  return allowUser;
};

export const isSupport = (user?: LoggedInUser) => {
  return hasRole(allRoles.SUPPORT_ROLES, user);
};

export const isSupplier = (user?: LoggedInUser) => {
  return hasRole(allRoles.SUPPLIER_ROLES, user);
};

//Can edit verified contacts
export const isBuyer = (user?: LoggedInUser) => {
  return !hasRole(allRoles.ADMIN_ACCESS_ROLES, user);
};

export const hasAdminRoles = (user?: LoggedInUser) => {
  return hasRole(allRoles.ADMIN_ACCESS_ROLES, user);
};

//Can edit verified contacts
export const canEditVerified = () => {
  // TODO we should not call the userUser hook here and pass the user instead to this function
  //      but since this function is called to create, it eeds some debugging and bigger refactoring
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const user = useUser();
  const authorizedRoles = ['chemberry-support', 'chemberry-editor'];
  return hasRole(authorizedRoles, user);
};

export const hasRole = (validRoles: string[], user?: LoggedInUser) => {
  let allowUser = false;
  if (user && validRoles) {
    validRoles.forEach(role => {
      if (user.roles?.includes(role)) {
        allowUser = true;
      }
    });
  }
  return allowUser;
};

export const getUserHasFeature = (
  user: LoggedInUser,
  currentCompany: string,
  feature: string
) => {
  if (isSupport(user)) return true;
  const companyFeatures = user?.companies?.find(
    company => company.key === currentCompany
  );
  const hasFeature = companyFeatures?.features?.[feature] ?? false;
  return hasFeature;
};

/**
 * @param validPermissions
 * @param requiredLevels
 * @param user
 * @returns true if at least one of the validPermissions is present in the user permissions and the level is correct
 */
export const hasPermission = (
  validPermissions: PermissionKey[],
  requiredLevels: PermissionLevel[],
  user?: LoggedInUser
): boolean => {
  // if support then allow him to see
  if (isSupport(user)) return true;

  if (user?.permissions) {
    return validPermissions.some(permission => {
      // Check if the user has the required permission and level
      const userLevel = user?.permissions?.[permission];
      return userLevel ? requiredLevels.includes(userLevel) : false;
    });
  }

  return false;
};

export enum CovaloPermissionKey {
  FORMULATIONS_TABLE_PROCEDURE_SHOW = 'formulations_table_procedure_show',
  LEADS_SHOW = 'leads_show',
  LEADS_TABLE_SHOW = 'leads_table_show',
  LEADS_TABLE_SHOW_ALL = 'leads_table_show_all',
  LEADS_COMPANY_SELECTOR_SHOW = 'leads_company_selector_show',
  EDITOR_PRODUCT_WRITE = 'editor_product_write',
  EDITOR_COMPANY_ALL = 'editor_company_all', // edit any company
  ANALYTICS_SHOW = 'analytics_show',
  ANALYTICS_SHOW_ALL = 'analytics_show_all' // for support only
}

const allRoles = {
  ALL_AND_EXT: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user',
    'buyer',
    'buyer-manager',
    'event-admin'
  ],
  ALL: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user',
    'buyer',
    'buyer-manager'
  ],
  EXTERNAL: ['event-admin'],
  VIEW_COMPANY: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user'
  ],
  CREATE_COMPANY: ['supplier-manager', 'chemberry-support', 'chemberry-editor'],
  EDIT_COMPANY: [
    'supplier-manager',
    'supplier-editor',
    'chemberry-support',
    'chemberry-editor'
  ],

  CREATE_PRODUCTS: ['supplier-manager', 'supplier-editor', 'chemberry-support'],
  PRODUCTS: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user'
  ],
  EDIT_PRODUCTS: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor'
  ],

  PROJECT_SPACE: [
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin',
    'buyer',
    'buyer-manager'
  ],
  CREATE_PROJECT: [
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin',
    'buyer',
    'buyer-manager'
  ],
  EDIT_PROJECT: [
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin',
    'buyer',
    'buyer-manager'
  ],
  SUPPLIER_ROLES: [
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user'
  ],
  BUYER_ROLES: ['buyer', 'buyer-manager'],
  ADMIN_ACCESS_ROLES: [
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor',
    'supplier-user',
    'chemberry-support',
    'chemberry-editor',
    'chemberry-admin'
  ],
  EVENT_ADMIN: ['event-admin'],
  SUPPORT_ROLES: ['chemberry-support', 'chemberry-editor', 'chemberry-admin'],
  MANAGER_ROLES: ['supplier-manager', 'buyer-manager'],
  EDIT_QUOTE: [
    'chemberry-support',
    'chemberry-editor',
    'supplier-manager',
    'supplier-manager-unverified',
    'supplier-editor'
  ],
  /**
   * the Roles user who are allowed to access brand portal even without being added to it
   *  make sure they have also access to the /event endpoint
   */
  CHEMBERRY_SUPPORT: ['chemberry-support']
};

Object.freeze(allRoles);

export default allRoles;
