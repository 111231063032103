import { CovaloPermissionKey as CovaloPermissionKey } from 'helpers/Roles';
import {
  BrandPortalPermissionKey,
  PortalPermissionLevel,
  PortalPermissions,
  SupplierPortalPermissionKey
} from 'portals/types';

export enum CovaloPermissionLevel {
  VIEW = 'view'
}

export type CovaloPermissions = {
  [key in CovaloPermissionKey]?: CovaloPermissionLevel;
};

export type Permissions = CovaloPermissions & PortalPermissions;

export type PermissionKey =
  | CovaloPermissionKey
  | SupplierPortalPermissionKey
  | BrandPortalPermissionKey;

export type PermissionLevel = CovaloPermissionLevel | PortalPermissionLevel;
