export enum CertificationsValues {
  COSMOS = 'COSMOS',
  NATRUE = 'NATRUE',
  RSPO = 'RSPO',
  CHINA_APPROVED = 'China-approved',
  ISO_16128 = 'ISO 16128',
  LEAPING_BUNNY = 'Leaping Bunny',
  VEGETARIAN_SOCIETY = 'Vegetarian Society Approved',
  VEGAN_SOCIETY = 'Vegan Society',
  HALAL = 'Halal',
  ALLERGY_CERTIFIED = 'Allergy Certified',
  USDA_CERTIFIED_BIOBASED = 'USDA Certified BioBased',
  CHEMFORWARD_SAFER = 'ChemFORWARD SAFER'
}
export enum CompanyTypes {
  FRAGRANCE_HOUSE = 'FRAGRANCE_HOUSE',
  EQUIPMENT_PROVIDER = 'EQUIPMENT_PROVIDER',
  SOFTWARE_PROVIDER = 'SOFTWARE_PROVIDER',
  TRADE_GOV_ORG = 'TRADE_GOV_ORG',
  BEAUTY_BRAND_BUYER_OF_RAW_MATERIALS_AND_INGREDIENTS = 'BEAUTY_BRAND_BUYER_OF_RAW_MATERIALS_AND_INGREDIENTS',
  CERTIFICATION_BODY = 'CERTIFICATION_BODY',
  CONTRACT_MANUFACTURER = 'CONTRACT_MANUFACTURER',
  CONSULTING = 'CONSULTING',
  DISTRIBUTOR = 'DISTRIBUTOR',
  FORMULATION_SERVICE = 'FORMULATION_SERVICE',
  LOGISTICS = 'LOGISTICS',
  MEDIA = 'MEDIA',
  PACKAGING = 'PACKAGING',
  SERVICE_PROVIDER = 'SERVICE_PROVIDER',
  SUPPLIER_OF_RAW_MATERIALS_AND_INGREDIENTS = 'SUPPLIER_OF_RAW_MATERIALS_AND_INGREDIENTS',
  TESTING_SERVICE = 'TESTING_SERVICE',
  UNIVERSITY = 'UNIVERSITY',
  UNKNOWN = 'UNKNOWN'
}

export enum QuantityUnitType {
  MILLIGRAM = 'MILLIGRAM',
  GRAM = 'GRAM',
  KILOGRAM = 'KILOGRAM',
  OUNCE = 'OUNCE',
  POUND = 'POUND',
  STONE = 'STONE',
  TONNE = 'TONNE',
  MILLILITERS = 'MILLILITERS',
  LITER = 'LITER',
  CUBIC_METER = 'CUBIC_METER',
  CUP = 'CUP',
  FLUID_OUNCE = 'FLUID_OUNCE',
  UNIT = 'UNIT'
}
