import { IS_UXBEAUTY } from 'components/constants/Constants';
import { ProductType } from 'types/Products';
import { QuantityUnitType } from './ReferenceDataTS';

export const CompanyTypes = [
  { value: 'FRAGRANCE_HOUSE', label: 'Fragrance house' },
  { value: 'EQUIPMENT_PROVIDER', label: 'Equipment provider' },
  { value: 'SOFTWARE_PROVIDER', label: 'Software provider' },
  {
    value: 'TRADE_GOV_ORG',
    label: 'Trade association/Government organization'
  },
  {
    value: 'BEAUTY_BRAND_BUYER_OF_RAW_MATERIALS_AND_INGREDIENTS',
    label: 'Consumer care brand'
  },
  { value: 'CERTIFICATION_BODY', label: 'Certification body' },
  { value: 'CONTRACT_MANUFACTURER', label: 'Contract manufacturer' },
  { value: 'CONSULTING', label: 'Consulting company' },
  { value: 'DISTRIBUTOR', label: 'Distributor' },
  { value: 'FORMULATION_SERVICE', label: 'Formulation service' },
  { value: 'LOGISTICS', label: 'Logistics company' },
  // { value: '*PENDING*', label: 'Manufacturer of raw materials and ingredients' },
  // { value: '*PENDING*', label: 'Manufacturer & distributor of raw materials and ingredients' },
  { value: 'MEDIA', label: 'Media' },
  { value: 'PACKAGING', label: 'Packaging company' },
  // { value: '*PENDING*', label: 'Regulatory company' },
  { value: 'SERVICE_PROVIDER', label: 'Service Provider' },
  {
    value: 'SUPPLIER_OF_RAW_MATERIALS_AND_INGREDIENTS',
    label: 'Supplier of raw materials and ingredients'
    // hide: true
  },
  { value: 'TESTING_SERVICE', label: 'Testing service company' },

  { value: 'UNIVERSITY', label: 'University' },
  { value: 'UNKNOWN', label: 'Unknown', hide: true }
];

export const DocumentAccessPolicies = [
  { value: 'PUBLIC', label: 'Publicly Available' },
  { value: 'CONFIDENTIAL', label: 'Confidential' }
];

export const ProductTypes = [
  { value: ProductType.INGREDIENTS, label: 'Ingredients' },
  {
    value: ProductType.FORMULATIONS,
    label: IS_UXBEAUTY ? 'Concepts' : 'Formulations'
  },
  { value: ProductType.SERVICES, label: 'Services' }
];
export const getProductTypeSingulars = (/** @type {ProductType} */ type) => {
  switch (type) {
    case ProductType.INGREDIENTS:
      return 'Ingredient';
    case ProductType.FORMULATIONS:
      return IS_UXBEAUTY ? 'Concept' : 'Formulation';
    case ProductType.SERVICES:
      return 'Service';
  }
};

export const ContactTypes = [
  { value: 'PHONE', label: 'Phone' },
  { value: 'EMAIL', label: 'Email' }
];

export const Segments = [
  { value: 'PERSONALCARE', label: 'Personal Care' },
  { value: 'HOMECARE', label: 'Home Care' }
];
export const SegmentsFoodOnly = [{ value: 'FOOD', label: 'Food' }];

export const Media = [
  { value: 'linkedin', label: 'LinkedIn' },
  { value: 'twitter', label: 'Twitter' },
  { value: 'youtube', label: 'Youtube' },
  { value: 'facebook', label: 'Facebook' },
  { value: 'instagram', label: 'Instagram' }
];

export const ServiceTypes = [
  { value: 'TESTING', label: 'Testing' },
  { value: 'CONSULTING', label: 'Consulting' },
  { value: 'CONTRACT_MANUFACTURING', label: 'Contract Manufacturing' },
  { value: 'REGULATORY_AND_COMPLIANCE', label: 'Regulatory and Compliance' },
  { value: 'FORMULATION', label: 'Formulation' },
  { value: 'PACKAGING', label: 'Packaging' },
  { value: 'RESEARCH_AND_DEVELOPMENT', label: 'Research and Development' },
  { value: 'EQUIPMENT', label: 'Equipment' },
  { value: 'SOFTWARE', label: 'Software' },
  { value: 'TRADE_ASSOCIATION', label: 'Trade Association' },
  { value: 'MEDIA', label: 'Media' }
];

export const PaymentTypes = [
  { value: 'PIA', label: 'PIA — Payment in advance' },
  { value: 'NET_7', label: 'Net 7 — Payment 7 days after invoice date' },
  { value: 'NET_10', label: 'Net 10 — Payment 10 days after invoice date' },
  { value: 'NET_30', label: 'Net 30' },
  { value: 'NET_60', label: 'Net 60' },
  { value: 'NET_90', label: 'Net 90' },
  { value: 'NET_1_10__30', label: '1% 10 Net 30' },
  { value: 'EOM', label: 'EOM' },
  { value: 'MFI_21', label: '21 MFI' },
  { value: 'COD', label: 'COD' },
  { value: 'CASH_ACCOUNT', label: 'Cash account' },
  { value: 'LETTER_OF_CREDIT', label: 'Letter of credit' },
  { value: 'BILL_OF_EXCHANGE', label: 'Bill of exchange' },
  { value: 'CND', label: 'CND' },
  { value: 'CBS', label: 'CBS' },
  { value: 'CIA', label: 'CIA' },
  { value: 'CWO', label: 'CWO' },
  { value: 'MD_1', label: '1MD' },
  { value: 'MD_2', label: '2MD' },
  { value: 'CONTRA', label: 'Contra' },
  { value: 'STAGE_PAYMENT', label: 'Stage payment' }
];

export const QuantityUnitTypes = [
  { value: QuantityUnitType.MILLIGRAM, label: 'mg' },
  { value: QuantityUnitType.GRAM, label: 'g' },
  { value: QuantityUnitType.KILOGRAM, label: 'kg' },
  { value: QuantityUnitType.OUNCE, label: 'oz' },
  { value: QuantityUnitType.POUND, label: 'lb' },
  { value: QuantityUnitType.STONE, label: 'st' },
  { value: QuantityUnitType.TONNE, label: 't' },
  { value: QuantityUnitType.MILLILITERS, label: 'ml' },
  { value: QuantityUnitType.LITER, label: 'l' },
  { value: QuantityUnitType.CUBIC_METER, label: 'm3' },
  { value: QuantityUnitType.CUP, label: 'cup' },
  { value: QuantityUnitType.FLUID_OUNCE, label: 'fl oz' }
];

export const GHSOptions = [
  { value: 'Flammable Liquids', label: 'Flammable Liquids' },
  { value: 'Self-Reactive Substances', label: 'Self-Reactive Substances' },
  { value: 'Aerosols', label: 'Aerosols' }
];

export const Regions = [
  { value: 'APAC', label: 'Asia-Pacific' },
  { value: 'EMEA', label: 'Europe, the Middle East and Africa' },
  { value: 'LATAM', label: 'Latin America' },
  { value: 'NORAM', label: 'North America' }
];

export const OriginOptions = [
  { value: 'BOTANICAL', label: 'Botanical' },
  { value: 'SYNTHETIC', label: 'Synthetic' },
  { value: 'MINERAL', label: 'Mineral' },
  { value: 'ANIMAL', label: 'Animal' },
  { value: 'BIOTECHNOLOGICAL', label: 'Biotechnological' }
];

export const CertificationOptions = [
  { value: 'COSMOS', label: 'COSMOS' },
  { value: 'NATRUE', label: 'NATRUE' },
  { value: 'RSPO', label: 'RSPO' },
  { value: 'CHINA_APPROVED', label: 'China-approved' },
  { value: 'ISO_16128', label: 'ISO 16128' },
  { value: 'LEAPING_BUNNY', label: 'Leaping Bunny' },
  { value: 'VEGETARIAN_SOCIETY', label: 'Vegetarian Society Approved' },
  { value: 'VEGAN_SOCIETY', label: 'Vegan Society' },
  { value: 'HALAL', label: 'Halal' },
  { value: 'ALLERGY_CERTIFIED', label: 'Allergy Certified' },
  { value: 'USDA_CERTIFIED_BIOBASED', label: 'USDA Certified BioBased' },
  { value: 'CHEMFORWARD_SAFER', label: 'ChemFORWARD SAFER' }
];

export const ItemsPerPage = [
  { label: 5, value: 5 },
  { label: 10, value: 10 },
  { label: 20, value: 20 },
  { label: 50, value: 50 }
];

export const ProjectVisibility = [
  { value: 'MEMBERS_ONLY', label: 'Members Only' },
  { value: 'COMPANY', label: 'Company' }
];

export const ProjectCategory = [
  { value: 'RESEARCH', label: 'Research' },
  { value: 'PRODUCT_DEVELOPMENT', label: 'Product Development' },
  { value: 'PRODUCT_IMPROVEMENT', label: 'Product Improvement' }
];
export const UserRoles = [
  { value: 'MANAGER', label: 'Manager' },
  { value: 'MEMBER', label: 'Member' },
  { value: 'SUPPLIER', label: 'Supplier' }
];

export const ContactsStatus = [
  { value: 'CANCELED', label: 'Canceled' },
  { value: 'CLOSED', label: 'Closed' },
  { value: 'REQUESTED', label: 'Open' }
];
export const ContactsTypes = [
  { value: 'SAMPLE', label: 'Sample' },
  { value: 'QUOTE', label: 'Quotation' },
  { value: 'DOCUMENT', label: 'Document' },
  { value: 'CONTACT', label: 'Contact' }
];

// Please consider the UXB special cases when changing values here!
export const FilesTypes = [
  { value: 'SDS', label: 'Safety Data Sheet (SDS)' },
  { value: 'TDS', label: 'Technical Data Sheet (TDS)' },
  { value: 'SPECIFICATION', label: 'Specification' },
  { value: 'BROCHURE', label: 'Brochure' },
  { value: 'ALLERGEN_DECLARATION', label: 'Allergen declaration' },
  { value: 'ANIMAL_TESTING_STATEMENT', label: 'Animal testing statement' },
  { value: 'BSE_TSE_STATEMENT', label: 'BSE/TSE Statement' },
  { value: 'CITES_DECLARATION', label: 'CITES declaration' },
  { value: 'CMR_STATEMENT', label: 'CMR statement' },
  { value: 'COA', label: 'Certificate of Analysis (CoA)' },
  { value: 'COMPOSITION_STATEMENT', label: 'Composition statement' },
  { value: 'ECOLOGICAL_SUMMARY', label: 'Ecological summary' },
  { value: 'EFFICACY_TEST_REPORT', label: 'Efficacy test report' },
  { value: 'FORMULATION', label: 'Formulation' },
  { value: 'GMO_STATEMENT', label: 'GMO statement' },
  { value: 'IMPURITY_STATEMENT', label: 'Impurity statement' },
  { value: 'MANUFACTURING_PROCEDURE', label: 'Manufacturing procedure' },
  { value: 'MICROPLASTIC_STATEMENT', label: 'Microplastic statement' },
  { value: 'MSDS', label: 'MSDS' },
  { value: 'NANOMATERIAL_STATEMENT', label: 'Nanomaterial statement' },
  { value: 'NONE', label: 'None' },
  { value: 'ORIGIN_STATEMENT', label: 'Origin statement' },
  { value: 'OTHER', label: 'Other' },
  { value: 'REACH_DECLARATION', label: 'REACH declaration' },
  { value: 'TOXICOLOGICAL_SUMMARY', label: 'Toxicological summary' },
  { value: 'VEGETARIAN_VEGAN_STATEMENT', label: 'Vegetarian/vegan statement' }
];

// This needs a nicer structure in the future, in case there will be more special cases.
export const FilesTypesForUxbConcepts = [
  { value: 'FORMULATION', label: 'Formulation' }
];

export const FilesTypesForUxbIngredients = [
  { value: 'SDS', label: 'Safety Data Sheet (SDS)' },
  { value: 'TDS', label: 'Technical Data Sheet (TDS)' },
  { value: 'BSE_TSE_STATEMENT', label: 'BSE/TSE Statement' },
  { value: 'CITES_DECLARATION', label: 'CITES declaration' },
  { value: 'CMR_STATEMENT', label: 'CMR statement' },
  { value: 'OTHER', label: 'Other' }
];

export const statusOptions = [
  { value: 'TO_BE_REVIEWED', label: 'To be reviewed' },
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'REJECTED', label: 'Rejected' }
];
export const BooleanOptions = (trueLabel, falseLabel) => [
  { value: true, label: trueLabel },
  { value: false, label: falseLabel }
];

export const PackagingOptions = [
  { value: 'BAG', label: 'Bag' },
  { value: 'BALE', label: 'Bale' },
  { value: 'BIGBAG', label: 'BigBag' },
  { value: 'BOTTLE', label: 'Bottle' },
  { value: 'BOTTLE_GLASS', label: 'Bottle (glass)' },
  { value: 'BOTTLE_PLASTIC', label: 'Bottle (plastic)' },
  { value: 'BOX', label: 'Box' },
  { value: 'CAN', label: 'Can' },
  { value: 'CAN_METAL', label: 'Can (metal)' },
  { value: 'CAN_PLASTIC', label: 'Can (plastic)' },
  { value: 'CARTON', label: 'Carton' },
  { value: 'DRUM_FIBER', label: 'Drum (fiber)' },
  { value: 'DRUM_PLASTIC', label: 'Drum (plastic)' },
  { value: 'DRUM_STEEL', label: 'Drum (steel)' },
  { value: 'FCL', label: 'FCL' },
  { value: 'FTL', label: 'FTL' },
  { value: 'IBC', label: 'IBC' },
  { value: 'ISO', label: 'ISO' },
  { value: 'PALLET', label: 'Pallet' },
  { value: 'OTHER', label: 'Other' }
];

export const IncoTerms = [
  { value: 'CFR', label: 'CFR' },
  { value: 'CIF', label: 'CIF' },
  { value: 'CIP', label: 'CIP' },
  { value: 'CPT', label: 'CPT' },
  { value: 'DAP', label: 'DAP' },
  { value: 'DDP', label: 'DDP' },
  { value: 'DPU', label: 'DPU' },
  { value: 'EXW', label: 'EXW' },
  { value: 'FAS', label: 'FAS' },
  { value: 'FCA', label: 'FCA' },
  { value: 'FOB', label: 'FOB' }
];

export const MajorCurrencies = [
  {
    value: 'AUD',
    label: 'AU$'
  },
  {
    value: 'BRL',
    label: 'R$'
  },
  {
    value: 'CAD',
    label: 'CA$'
  },
  {
    value: 'CHF',
    label: 'CHF'
  },
  {
    value: 'EUR',
    label: '€'
  },
  {
    value: 'GBP',
    label: '£'
  },
  {
    value: 'USD',
    label: '$'
  },
  {
    value: 'AED',
    label: 'AED'
  },
  {
    value: 'AFN',
    label: 'Af'
  },
  {
    value: 'ALL',
    label: 'ALL'
  },
  {
    value: 'AMD',
    label: 'AMD'
  },
  {
    value: 'ARS',
    label: 'AR$'
  },

  {
    value: 'AZN',
    label: 'man.'
  },
  {
    value: 'BAM',
    label: 'KM'
  },
  {
    value: 'BDT',
    label: 'Tk'
  },
  {
    value: 'BGN',
    label: 'BGN'
  },
  {
    value: 'BHD',
    label: 'BD'
  },
  {
    value: 'BIF',
    label: 'FBu'
  },
  {
    value: 'BND',
    label: 'BN$'
  },
  {
    value: 'BOB',
    label: 'Bs'
  }
];

export const Currencies = [
  {
    value: 'AED',
    label: 'AED'
  },
  {
    value: 'AFN',
    label: 'Af'
  },
  {
    value: 'ALL',
    label: 'ALL'
  },
  {
    value: 'AMD',
    label: 'AMD'
  },
  {
    value: 'ARS',
    label: 'AR$'
  },

  {
    value: 'AZN',
    label: 'man.'
  },
  {
    value: 'BAM',
    label: 'KM'
  },
  {
    value: 'BDT',
    label: 'Tk'
  },
  {
    value: 'BGN',
    label: 'BGN'
  },
  {
    value: 'BHD',
    label: 'BD'
  },
  {
    value: 'BIF',
    label: 'FBu'
  },
  {
    value: 'BND',
    label: 'BN$'
  },
  {
    value: 'BOB',
    label: 'Bs'
  },

  {
    value: 'BWP',
    label: 'BWP'
  },
  {
    value: 'BYN',
    label: 'Br'
  },
  {
    value: 'BZD',
    label: 'BZ$'
  },

  {
    value: 'CDF',
    label: 'CDF'
  },

  {
    value: 'CLP',
    label: 'CL$'
  },
  {
    value: 'CNY',
    label: 'CN¥'
  },
  {
    value: 'COP',
    label: 'CO$'
  },
  {
    value: 'CRC',
    label: '₡'
  },
  {
    value: 'CVE',
    label: 'CV$'
  },
  {
    value: 'CZK',
    label: 'Kč'
  },
  {
    value: 'DJF',
    label: 'Fdj'
  },
  {
    value: 'DKK',
    label: 'Dkr'
  },
  {
    value: 'DOP',
    label: 'RD$'
  },
  {
    value: 'DZD',
    label: 'DA'
  },
  {
    value: 'EEK',
    label: 'Ekr'
  },
  {
    value: 'EGP',
    label: 'EGP'
  },
  {
    value: 'ERN',
    label: 'Nfk'
  },
  {
    value: 'ETB',
    label: 'Br'
  },
  {
    value: 'GEL',
    label: 'GEL'
  },
  {
    value: 'GHS',
    label: 'GH₵'
  },
  {
    value: 'GNF',
    label: 'FG'
  },
  {
    value: 'GTQ',
    label: 'GTQ'
  },
  {
    value: 'HKD',
    label: 'HK$'
  },
  {
    value: 'HNL',
    label: 'HNL'
  },
  {
    value: 'HRK',
    label: 'kn'
  },
  {
    value: 'HUF',
    label: 'Ft'
  },
  {
    value: 'IDR',
    label: 'Rp'
  },
  {
    value: 'ILS',
    label: '₪'
  },
  {
    value: 'INR',
    label: 'Rs'
  },
  {
    value: 'IQD',
    label: 'IQD'
  },
  {
    value: 'IRR',
    label: 'IRR'
  },
  {
    value: 'ISK',
    label: 'Ikr'
  },
  {
    value: 'JMD',
    label: 'J$'
  },
  {
    value: 'JOD',
    label: 'JD'
  },
  {
    value: 'JPY',
    label: '¥'
  },
  {
    value: 'KES',
    label: 'Ksh'
  },
  {
    value: 'KHR',
    label: 'KHR'
  },
  {
    value: 'KMF',
    label: 'CF'
  },
  {
    value: 'KRW',
    label: '₩'
  },
  {
    value: 'KWD',
    label: 'KD'
  },
  {
    value: 'KZT',
    label: 'KZT'
  },
  {
    value: 'LBP',
    label: 'L.L.'
  },
  {
    value: 'LKR',
    label: 'SLRs'
  },
  {
    value: 'LTL',
    label: 'Lt'
  },
  {
    value: 'LVL',
    label: 'Ls'
  },
  {
    value: 'LYD',
    label: 'LD'
  },
  {
    value: 'MAD',
    label: 'MAD'
  },
  {
    value: 'MDL',
    label: 'MDL'
  },
  {
    value: 'MGA',
    label: 'MGA'
  },
  {
    value: 'MKD',
    label: 'MKD'
  },
  {
    value: 'MMK',
    label: 'MMK'
  },
  {
    value: 'MOP',
    label: 'MOP$'
  },
  {
    value: 'MUR',
    label: 'MURs'
  },
  {
    value: 'MXN',
    label: 'MX$'
  },
  {
    value: 'MYR',
    label: 'RM'
  },
  {
    value: 'MZN',
    label: 'MTn'
  },
  {
    value: 'NAD',
    label: 'N$'
  },
  {
    value: 'NGN',
    label: '₦'
  },
  {
    value: 'NIO',
    label: 'C$'
  },
  {
    value: 'NOK',
    label: 'Nkr'
  },
  {
    value: 'NPR',
    label: 'NPRs'
  },
  {
    value: 'NZD',
    label: 'NZ$'
  },
  {
    value: 'OMR',
    label: 'OMR'
  },
  {
    value: 'PAB',
    label: 'B/.'
  },
  {
    value: 'PEN',
    label: 'S/.'
  },
  {
    value: 'PHP',
    label: '₱'
  },
  {
    value: 'PKR',
    label: 'PKRs'
  },
  {
    value: 'PLN',
    label: 'zł'
  },
  {
    value: 'PYG',
    label: '₲'
  },
  {
    value: 'QAR',
    label: 'QR'
  },
  {
    value: 'RON',
    label: 'RON'
  },
  {
    value: 'RSD',
    label: 'din.'
  },
  {
    value: 'RUB',
    label: 'RUB'
  },
  {
    value: 'RWF',
    label: 'RWF'
  },
  {
    value: 'SAR',
    label: 'SR'
  },
  {
    value: 'SDG',
    label: 'SDG'
  },
  {
    value: 'SEK',
    label: 'Skr'
  },
  {
    value: 'SGD',
    label: 'S$'
  },
  {
    value: 'SOS',
    label: 'Ssh'
  },
  {
    value: 'SYP',
    label: 'SY£'
  },
  {
    value: 'THB',
    label: '฿'
  },
  {
    value: 'TND',
    label: 'DT'
  },
  {
    value: 'TOP',
    label: 'T$'
  },
  {
    value: 'TRY',
    label: 'TL'
  },
  {
    value: 'TTD',
    label: 'TT$'
  },
  {
    value: 'TWD',
    label: 'NT$'
  },
  {
    value: 'TZS',
    label: 'TSh'
  },
  {
    value: 'UAH',
    label: '₴'
  },
  {
    value: 'UGX',
    label: 'USh'
  },

  {
    value: 'UYU',
    label: '$U'
  },
  {
    value: 'UZS',
    label: 'UZS'
  },
  {
    value: 'VEF',
    label: 'Bs.F.'
  },
  {
    value: 'VND',
    label: '₫'
  },
  {
    value: 'XAF',
    label: 'FCFA'
  },
  {
    value: 'XOF',
    label: 'CFA'
  },
  {
    value: 'YER',
    label: 'YR'
  },
  {
    value: 'ZAR',
    label: 'R'
  },
  {
    value: 'ZMK',
    label: 'ZK'
  },
  {
    value: 'ZWL',
    label: 'ZWL$'
  }
];

export const QuoteStatus = [
  { value: 'ACCEPTED', label: 'Accepted' },
  { value: 'ARCHIVED', label: 'Archived' },
  { value: 'EXPIRED', label: 'Expired' },
  { value: 'PENDING', label: 'Pending' },
  { value: 'REJECTED', label: 'Rejected' }
];

//EVENTS

export const EventStatus = [
  { value: 'ENDED', label: 'Ended' },
  { value: 'ONGOING', label: 'Ongoing' },
  { value: 'UPCOMING', label: 'Upcoming' },
  { value: 'CANCELED', label: 'Canceled' }
];

export const EventTypes = [
  { value: 'EXHIBITION', label: 'Exhibition' },
  { value: 'TRADE_SHOW', label: 'Trade show' },
  { value: 'CONGRESS', label: 'Congress' },
  { value: 'EXPO', label: 'Expo' },
  { value: 'BRAND_PORTAL', label: 'Brand Portal' }
];

export const EventCompanyStatus = [
  { value: 'ENABLED', label: 'Enabled' },
  { value: 'DISABLED', label: 'Disabled' }
];

export const EventProductStatus = [
  { value: 'ENABLED', label: 'Listed' },
  { value: 'DISABLED', label: 'Not listed' }
];

export const userProductInterests = [
  // TODO: validate and add correct values here on the interests
  { label: 'Ingredients', value: 'Ingredients' },
  { label: 'Formulations', value: 'Formulations' },
  { label: 'Functions', value: 'Functions' },
  { label: 'Performance claims', value: 'performance claims' },
  { label: 'Sustainability claims', value: 'Sustainability claims' },
  { label: 'Applications', value: 'Applications' },
  { label: 'Certifications', value: 'Certifications' }
];

export const userPlatformInterests = [
  // TODO: validate and add correct values here on the interests
  { label: 'explore new products', value: 'explore new products' }
];

export const salutations = [
  { label: 'Mr.', value: 'Mr.' },
  { label: 'Mx.', value: 'Mx.' },
  { label: 'Mrs.', value: 'Mrs.' },
  { label: 'Miss', value: 'Miss' },
  { label: 'Ms', value: 'Ms' },
  { label: 'Sr.', value: 'Sr.' },
  { label: 'Dr.', value: 'Dr.' }
];
export const Departments = [
  {
    value: 'ACADEMIA',
    label: 'Academia'
  },
  {
    value: 'COMMUNICATIONS',
    label: 'Communications'
  },
  {
    value: 'CONSULTANT',
    label: 'Consultant'
  },
  {
    value: 'FORMULATION_DEVELOPMENT',
    label: 'Formulation development'
  },
  {
    value: 'FUNDAMENTAL_RESEARCH',
    label: 'Fundamental research'
  },
  {
    value: 'IT',
    label: 'IT'
  },
  {
    value: 'INNOVATION',
    label: 'Innovation'
  },
  {
    value: 'MANAGEMENT_BUSINESS_DEVELOPMENT',
    label: 'Management / Business development'
  },
  {
    value: 'MARKETING',
    label: 'Marketing'
  },
  {
    value: 'PERFUMER',
    label: 'Perfumer'
  },
  {
    value: 'PROCUREMENT',
    label: 'Procurement'
  },
  {
    value: 'PRODUCT_DEVELOPMENT_TECHNOLOGIST',
    label: 'Product development technologist'
  },
  {
    value: 'PRODUCTION',
    label: 'Production'
  },
  {
    value: 'QUALITY',
    label: 'Quality'
  },
  {
    value: 'REGULATORY_AFFAIRS',
    label: 'Regulatory affairs'
  },
  {
    value: 'SAFETY',
    label: 'Safety'
  },
  {
    value: 'SALES',
    label: 'Sales'
  },
  {
    value: 'SUPPLY_CHAIN',
    label: 'Supply chain'
  },
  {
    value: 'SUSTAINABILITY_CSR',
    label: 'Sustanability/CSR'
  },
  {
    value: 'OTHER',
    label: 'Other'
  }
];
export const LanguageLevelsOptions = [
  { value: 'NATIVE', label: 'Native' },
  { value: 'FULL_PROFESSIONAL', label: 'Full professional' },
  { value: 'PROFESSIONAL', label: 'Professional' },
  { value: 'LIMITED', label: 'Limited' },
  { value: 'ELEMENTARY', label: 'Elementary' }
];

//Methods
export const translate = (value, array) => {
  return array.find(item => item.value === value)?.label;
};
export const translateReverse = (label, array) => {
  return array.find(item => item.label === label)?.value;
};
export const valueToOption = (value, array) => {
  return array.find(item => item.value === value);
};

export const optionToValue = option => {
  return option?.value;
};

export const enumToOptions = enums => {
  const options = [];
  Object.values(enums).map(e => options.push({ value: e, label: e }));
  return options;
};

export const optionsToValue = options => {
  const arr = [];
  options.map(option => arr.push(option.value));
  return arr;
};

export const isRegion = region => {
  return Regions.some(item => item.value === region);
};

export const AssetsTypesForFoodIngredients = [
  { value: 'SDS', label: 'Safety Data Sheet (SDS)' },
  { value: 'TDS', label: 'Technical Data Sheet (TDS)' },
  { value: 'BROCHURES', label: 'Brochures' },
  { value: 'SPECIFICATION', label: 'Specification' },
  {
    value: 'CERTIFICATE_OF_ANALYSIS_(COA)',
    label: 'Certificate of Analysis (CoA)'
  },
  { value: 'ALLERGEN_DECLARATION', label: 'Allergen declaration' },
  { value: 'ANIMAL_TESTING_STATEMENT', label: 'Animal testing statement' },
  { value: 'BSE/_TSE_STATEMENT', label: 'BSE/ TSE Statement' },
  { value: 'COMPOSITION_STATEMENT', label: 'Composition statement' },
  { value: 'CITES_DECLARATION', label: 'CITES declaration' },
  { value: 'ECOLOGICAL_SUMMARY', label: 'Ecological summary' },
  { value: 'GMO_STATEMENT', label: 'GMO statement' },
  { value: 'IMPURITY_STATEMENT', label: 'Impurity statement' },
  { value: 'CMR_STATEMENT', label: 'CMR statement' },
  { value: 'MANUFACTURING_PROCEDURE', label: 'Manufacturing procedure' },
  { value: 'FORMULATIONS', label: 'Formulations' },
  { value: 'EFFICACY_TEST_REPORT', label: 'Efficacy Test Report' }
];

export const AssetTypes = [
  { value: 'SDS', label: 'Safety Data Sheet (SDS)' },
  {
    value: 'TDS',
    label: IS_UXBEAUTY ? 'Technical Leaflet' : 'Technical Data Sheet (TDS)'
  },
  { value: 'SPECIFICATION', label: 'Specification' },
  {
    value: 'BROCHURE',
    label: IS_UXBEAUTY ? 'Marketing Presentation' : 'Brochure'
  },
  { value: 'ALLERGEN_DECLARATION', label: 'Allergen declaration' },
  { value: 'ANIMAL_TESTING_STATEMENT', label: 'Animal testing statement' },
  { value: 'BSE_TSE_STATEMENT', label: 'BSE/TSE Statement' },
  { value: 'CITES_DECLARATION', label: 'CITES declaration' },
  { value: 'CMR_STATEMENT', label: 'CMR statement' },
  { value: 'COA', label: 'Certificate of Analysis (CoA)' },
  { value: 'COMPOSITION_STATEMENT', label: 'Composition statement' },
  { value: 'ECOLOGICAL_SUMMARY', label: 'Ecological summary' },
  { value: 'EFFICACY_TEST_REPORT', label: 'Efficacy test report' },
  { value: 'FORMULATION', label: 'Formulation' },
  { value: 'GMO_STATEMENT', label: 'GMO statement' },
  { value: 'IMPURITY_STATEMENT', label: 'Impurity statement' },
  { value: 'MANUFACTURING_PROCEDURE', label: 'Manufacturing procedure' },
  { value: 'MICROPLASTIC_STATEMENT', label: 'Microplastic statement' },
  { value: 'MSDS', label: 'MSDS' },
  { value: 'NANOMATERIAL_STATEMENT', label: 'Nanomaterial statement' },
  { value: 'NONE', label: 'None' },
  { value: 'ORIGIN_STATEMENT', label: 'Origin statement' },
  { value: 'OTHER', label: 'Other' },
  { value: 'REACH_DECLARATION', label: 'REACH declaration' },
  { value: 'TOXICOLOGICAL_SUMMARY', label: 'Toxicological summary' },
  { value: 'VEGETARIAN_VEGAN_STATEMENT', label: 'Vegetarian/vegan statement' }
];
