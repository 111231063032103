import { inviteUsers } from 'api/services/InviteService';
import { Countries } from 'api/types/countries';
import { useEffect, useState } from 'react';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import { useFieldArray, useForm } from 'react-hook-form';
import { FetchResponse, Option } from '../../types/global';
import CompanySelector from '../pages/products/CompanySelector';
import { toast } from 'react-toastify';
import { useUser } from 'context/User';
import Cancel from '@mui/icons-material/Cancel';
import Add from '@mui/icons-material/Add';

interface InviteModalProps {
  show: boolean;
  onHide: Function;
}

export interface InviteUserInterface {
  users: {
    firstname: string;
    lastname: string;
    email: string;
    country_code?: string;
    editor: boolean;
  }[];
}

export const InviteModal = ({ show, onHide }: InviteModalProps) => {
  const [companyKey, setCompanyKey] = useState<string>();

  const user = useUser();
  const {
    control,
    register,
    handleSubmit,
    formState: { isDirty }
  } = useForm({
    defaultValues: {
      users: [
        {
          firstname: '',
          lastname: '',
          email: '',
          country_code: '',
          editor: false
        }
      ]
    }
  });
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'users'
  });

  useEffect(() => {
    user?.assignedCompanies &&
      user?.assignedCompanies[0] &&
      setCompanyKey(user?.assignedCompanies[0].key);
  }, [user]);

  const handleSave = (values: InviteUserInterface) => {
    // before we invite we need to check if the user is inviting to one of his companies or external company
    // if he invited to another comapny we use diferent endpoint

    // const isUserCompany =
    //   user?.assignedCompanies &&
    //   user?.assignedCompanies.some(company => company.key === companyKey);

    companyKey &&
      // && isUserCompany
      // ?
      inviteUsers(
        companyKey,
        values,
        () => {
          onHide();
        },
        (error: FetchResponse) => {
          if (error?.response?.data) {
            const { data: errorData } = error?.response || undefined;
            if (
              errorData?.status === 400 &&
              errorData?.additional_data?.failures
            )
              errorData?.additional_data?.failures?.forEach(
                (failure: string) => {
                  failure.includes('already exists')
                    ? toast.error(
                        failure.replace('User', 'An account with email ')
                      )
                    : failure !== ''
                    ? toast.error(failure)
                    : //when failure is empty
                      toast.error('Something went wrong');
                }
              );

            //when error status is not 400 or we dont have failue object
          } else toast.error('Something went wrong');
        }
      );
  };

  return (
    <Modal
      show={show}
      onHide={() => onHide()}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="fixed-height-modal"
    >
      <Modal.Header closeButton>
        <Modal.Title id="contained-modal-title-center">
          Invite your colleagues
        </Modal.Title>
      </Modal.Header>
      <Modal.Body
        style={{
          scrollBehavior: 'smooth',
          height: 'fit-content',
          maxHeight: '70vh',
          overflowY: 'scroll',
          padding: '2rem'
        }}
      >
        <div className="w-30 required">
          <Form.Label>Company</Form.Label>
          <CompanySelector
            getAllCompanies={true}
            onSelect={(option: Option) => setCompanyKey(option.value)}
            defaultValue={
              user?.assignedCompanies !== undefined &&
              user?.assignedCompanies[0]
                ? {
                    value: user?.assignedCompanies[0].key,
                    label: user?.assignedCompanies[0].name
                  }
                : undefined
            }
            style={undefined}
            page={undefined}
          />
        </div>
        <Form
          onSubmit={handleSubmit(handleSave)}
          className="form"
          id="inviteForm"
        >
          {fields.map((item, index) => {
            return (
              <div
                className="d-flex  gap-2 align-items-center my-4"
                style={{
                  padding:
                    index !== 0 || fields.length > 1
                      ? '0 0 1em 1em'
                      : '0 1em 1em 1em',
                  boxShadow: '0 0 3px 0px #d9d9d9',
                  borderRadius: '4px'
                }}
                key={item.id}
              >
                <Row key={index}>
                  <Col lg={6}>
                    <Form.Group className="form-group required">
                      <Form.Label>First name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={'First name'}
                        {...register(`users.${index}.firstname`, {
                          required: true
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={6}>
                    <Form.Group className="form-group required">
                      <Form.Label>Last name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder={'Last name'}
                        {...register(`users.${index}.lastname`, {
                          required: true
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={8}>
                    <Form.Group className="form-group required">
                      <Form.Label>Email address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder={'Email'}
                        {...register(`users.${index}.email`, {
                          required: true
                        })}
                      />
                    </Form.Group>
                  </Col>
                  <Col lg={4}>
                    <Form.Group className="form-group">
                      <Form.Label>Country</Form.Label>
                      <Form.Select
                        {...register(`users.${index}.country_code`)}
                        placeholder="Country"
                      >
                        <option disabled value="">
                          Country
                        </option>
                        {Countries.map(country => (
                          <option value={country.value} key={country.label}>
                            {country.label}
                          </option>
                        ))}
                      </Form.Select>
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group className="form-group">
                      <Form.Check>
                        <Form.Check.Input
                          {...register(`users.${index}.editor`)}
                        />
                        <Form.Check.Label style={{ lineHeight: 'unset' }}>
                          Select Editor permissions if you would like your
                          colleague to help with reviewing or amending your
                          product data.
                        </Form.Check.Label>
                      </Form.Check>
                    </Form.Group>
                  </Col>
                </Row>

                {(index !== 0 || fields.length > 1) && (
                  <button className="btn" onClick={() => remove(index)}>
                    <Cancel fontSize="small" color="warning" />
                  </button>
                )}
              </div>
            );
          })}
        </Form>
      </Modal.Body>
      <div className="modified-modal-footer p-3">
        <div className="d-inline">
          <Button
            variant="falcon-default"
            type="button"
            onClick={() => {
              append({
                firstname: '',
                lastname: '',
                email: '',
                country_code: '',
                editor: false
              });
            }}
          >
            <Add /> users
          </Button>
        </div>
        <div className="right">
          <Button
            type="submit"
            form={`inviteForm`}
            disabled={!companyKey || !isDirty}
          >
            Invite
          </Button>
        </div>
      </div>
    </Modal>
  );
};
