import {
  createCompany,
  updateCompany,
  updateCompanyPermissions
} from '../../../../api/services/CompaniesService';

import i18n from '../../../../i18n';
import allRoles, {
  hasRole,
  isBuyer,
  isSupplier,
  isSupport
} from '../../../../helpers/Roles';

export const updatePermissions = (
  company,
  formData,
  handleSuccess,
  handleError
) => {
  const permissions = permissionsToEntity(formData);
  updateCompanyPermissions(company, permissions, handleSuccess, handleError);
};

export const permissionsToEntity = data => {
  if (!data['permissions']) {
    data['permissions'] = {};
  }

  data['permissions']['exclude'] = [];
  if (data['excluded-types']) {
    let types = [];
    data['excluded-types'].forEach(item => {
      types.push(item.value);
    });
    data['permissions']['exclude'] = types;
  }
  return data['permissions'];
};

export const numberOfEmployeesToEntity = data => {
  if (data['details']?.['number_employees']) {
    data['details']['number_employees'] =
      data['details']['number_employees'].value;
  }
  return data;
};
export const numberOfEmployeesToForm = data => {
  if (data['details']?.['number_employees']) {
    let item = data['details']['number_employees'] ?? '';
    data['details']['number_employees'] = {
      value: item,
      label: item
    };
  }
};

export const permissionsToForm = data => {
  const t = i18n.t;
  data['excluded-types'] = [];
  if (data['permissions'] && data['permissions']['exclude']) {
    data['permissions']['exclude'].forEach(item => {
      data['excluded-types'].push({
        value: item,
        label: t(`company.types.${item}`)
      });
    });
  }
};

export const submit = (company, formData, handleSuccess, handleError) => {
  let updatedEntity = toEntity(formData);

  if (company !== undefined) {
    updateCompany(company.key, updatedEntity, handleSuccess, handleError);
  } else {
    createCompany(updatedEntity, handleSuccess, handleError);
  }
};

export const toEntity = company => {
  offeringsToEntity(company);
  numberOfEmployeesToEntity(company);
  return company;
};

export const toForm = company => {
  permissionsToForm(company);
  numberOfEmployeesToForm(company);

  return company;
};

export const offeringsToEntity = data => {
  if (data['offeringForm'] === undefined) {
    return;
  }

  data['offering'] = [];
  // eslint-disable-next-line array-callback-return
  data['offeringForm'].map(offering => {
    data['offering'].push(offering.value);
  });
};

export const filterAvailableRolesForGrantRevoke = user => {
  if (isSupport(user)) {
    return supportRoles;
  } else if (isSupplier(user) && hasRole(allRoles.MANAGER_ROLES, user)) {
    return supplierRoles;
  } else if (isBuyer(user) && hasRole(allRoles.MANAGER_ROLES, user)) {
    return buyerRoles;
  } else {
    return [];
  }
};

const supportRoles = [
  'COVALO_SUPER',
  'COVALO_EDITOR',
  'COVALO_ADMIN',
  'BUYER',
  'BUYER_MANAGER',
  'SUPPLIER_USER',
  'SUPPLIER_EDITOR',
  'SUPPLIER_MANAGER'
];
const supplierRoles = ['SUPPLIER_USER', 'SUPPLIER_EDITOR', 'SUPPLIER_MANAGER'];
const buyerRoles = ['BUYER', 'BUYER_MANAGER'];

export const numberOfEmployeesOptions = [
  { value: '1-10', label: '1-10' },
  { value: '11-50', label: '11-50' },
  { value: '51-200', label: '51-200' },
  { value: '201-500', label: '201-500' },
  { value: '501-1000', label: '501-1000' },
  { value: '1001-5000', label: '1001-5000' },
  { value: '5001-10000', label: '5001-10000' },
  { value: '10001+', label: '10001+' }
];
